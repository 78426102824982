import React from 'react';

import { IAddressInputAddress } from '@opendoor/cinderblocks/growth/AddressInput/AddressInput';

import {
  OdProtosCasaData_Result,
  OdProtosSellReceptionData_SellerInput_AnalyticsMetadata_Product,
  OdProtosSellReceptionData_SellerInput_Channel,
} from '__generated__/athena';

import { startAddressVerification } from '../components/api/addressInputHelpers';
import { AutoCompletePrediction } from '../components/novo/NovoAddressInput';
import { handleAddressVerificationSuccess } from '../components/shared/SellerFlowAddressInput';
import { setCookie } from '../components/shared/trackers';
import { useObservability } from './observability';

export type SelectedAddress = IAddressInputAddress & {
  id?: string;
  result?: OdProtosCasaData_Result[];
  source?: 'google' | 'casa';
};

export const ADDRESS_INVALID_RESULT: OdProtosCasaData_Result[] = [
  'ADDRESS_NIL',
  'ADDRESS_UNKNOWN',
  'ADDRESS_MISSING_PREMISE',
  'ADDRESS_INVALID_PREMISE',
  'ADDRESS_NOT_SUPPORTED',
  'ADDRESS_NOT_REAL',
  'ADDRESS_INVALID',
  'ADDRESS_INVALID_STREET',
  'ADDRESS_INVALID_SUB_PREMISE',
  'ADDRESS_MISSING_BOX_NUMBER',
  'ADDRESS_INVALID_BOX_NUMBER',
  'ADDRESS_EXTRANEOUS_INPUT',
];

interface OnboardingFlowInput {
  address: SelectedAddress;
  product: OdProtosSellReceptionData_SellerInput_AnalyticsMetadata_Product;
  trackEvent: ReturnType<typeof useObservability>['trackEvent'];
  trackingTaxonomy?: string;
  channel?: OdProtosSellReceptionData_SellerInput_Channel;
  trackingKeywords?: string;
  inputLocation?: string;
  onSuccess?: (address: IAddressInputAddress, token: string, pdpUrl?: string) => void;
  partnerReferralId?: string;
  pageSetPath?: string;
  addressUUID?: string;
  canRedirect?: boolean;
}

export const gateProceedToOnboardingFlow = async ({
  address,
  product,
  trackEvent,
  trackingTaxonomy,
  channel,
  trackingKeywords,
  inputLocation,
  onSuccess,
  partnerReferralId,
  pageSetPath,
  addressUUID,
  canRedirect = true,
}: OnboardingFlowInput) => {
  if (trackingTaxonomy) {
    setCookie('sourceForTracking', trackingTaxonomy, 1);
  }

  const error = await startAddressVerification({
    addressUUID,
    address,
    product,
    channel,
    trackingKeywords,
    inputLocation,
    onSuccess: (token, pdpUrl, pageSetPath) => {
      handleAddressVerificationSuccess({
        address,
        token,
        trackEvent,
        pdpUrl,
        pageSetPath,
        onSuccess,
        inNewAddressEntryExperiment: true,
      });
    },
    partnerReferralId,
    pageSetPath,
  });

  if (error) {
    if (error.type === 'invalid-address') {
      if (canRedirect) {
        window.location.href = '/manual-address-entry';
      } else {
        throw new Error(error.message);
      }
    } else {
      window.location.href = '/error';
    }
  }
};

export type AddressValidationContextProps = {
  validatedAddress?: SelectedAddress;
  setValidatedAddress: React.Dispatch<React.SetStateAction<SelectedAddress | undefined>>;
  addressResults: OdProtosCasaData_Result[];
  setAddressResults: React.Dispatch<React.SetStateAction<OdProtosCasaData_Result[]>>;
  isPredictionSelectLoading: boolean;
  setIsPredictionSelectLoading: React.Dispatch<React.SetStateAction<boolean>>;
  firstPrediction: AutoCompletePrediction | null;
  setFirstPrediction: React.Dispatch<React.SetStateAction<AutoCompletePrediction | null>>;
  partnerReferralId?: string;
  setPartnerReferralId: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const AddressValidationContext = React.createContext<AddressValidationContextProps>({
  validatedAddress: undefined,
  setValidatedAddress: () => null,
  addressResults: [],
  setAddressResults: () => null,
  isPredictionSelectLoading: false,
  setIsPredictionSelectLoading: () => null,
  firstPrediction: null,
  setFirstPrediction: () => null,
  partnerReferralId: undefined,
  setPartnerReferralId: () => null,
});

export const useAddressValidationContext = () => React.useContext(AddressValidationContext);

export const AddressValidationContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [validatedAddress, setValidatedAddress] = React.useState<SelectedAddress | undefined>(
    undefined,
  );
  const [addressResults, setAddressResults] = React.useState<OdProtosCasaData_Result[]>([]);
  const [isPredictionSelectLoading, setIsPredictionSelectLoading] = React.useState(false);
  const [firstPrediction, setFirstPrediction] = React.useState<AutoCompletePrediction | null>(null);
  const [partnerReferralId, setPartnerReferralId] = React.useState<string | undefined>(undefined);
  return (
    <AddressValidationContext.Provider
      value={{
        validatedAddress,
        setValidatedAddress,
        addressResults,
        setAddressResults,
        isPredictionSelectLoading,
        setIsPredictionSelectLoading,
        firstPrediction,
        setFirstPrediction,
        partnerReferralId,
        setPartnerReferralId,
      }}
    >
      {children}
    </AddressValidationContext.Provider>
  );
};
